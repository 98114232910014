import React from 'react';
import { Button, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirect: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    // border: '4px dotted pink',
    width: '100vw',
  },
  paper: {
    border: '2px solid #000',
    backgroundColor: '#000',
    padding: theme.spacing(1),
    outline: 0,

    [theme.breakpoints.down('sm')]: {
      minHeight: '90vh',
      maxWidth: '90vw',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
      maxHeight: '90vh',
      overflowY: 'auto',
      width: '45vw',
    },
    // [theme.breakpoints.up('lg')]: {
    //   height: '50vh',
    //   width: '50vw',
    // },
  },
}));

export default function TransitionsModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    console.log({ open });
    setOpen(true);
  };

  const handleClose = () => {
    console.log({ open });
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex', position: 'relative', maxwidth: '100%' }}>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        className='button'
      >
        Features
      </Button>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ outline: 'none' }}
      >
        <Fade in={open}>
          <div>
            <div className={classes.paper} style={{ outline: 'none' }}>
              <Typography variant='h3'> Current Features </Typography>
              <br />
              <ul>
                <li>
                  <Typography>
                    Track both network and cache requests through time
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Revert the cache to any previous query or mutation
                  </Typography>
                </li>
                <li>
                  <Typography>Reversions re-render to the DOM</Typography>
                </li>
              </ul>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
