import React from 'react';
import { Button, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { teamData } from '../cardData/teamData';
import TeamCard from '../components/teamCard';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    maxWidth: '100vw',
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: '2px solid #000',
    backgroundColor: '#000',
    padding: theme.spacing(1),
    outline: 0,
    height: '25rem',
    width: '70rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '50rem',
      width: '20rem',
      padding: '1rem 0',
    },
    // [theme.breakpoints.up('md')]: {
    //   height: '50rem',
    //   width: '90rem',
    // },
    // [theme.breakpoints.up('lg')]: {},
  },
}));

export default function TransitionsModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    console.log({ open });
    setOpen(true);
  };

  const handleClose = () => {
    console.log({ open });
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant='outlined'
        color='primary'
        onClick={handleOpen}
        className='button'
      >
        <Typography variant='p'>The Team</Typography>
      </Button>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{ alignItems: 'center', justifyContent: 'center' }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {teamData.map((member) => (
              <TeamCard memberData={member}></TeamCard>
            ))}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
