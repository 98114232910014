export const teamData = [
  {
    name: 'Matt Severyn',
    github: 'https://github.com/mtseveryn',
    linkedin: 'https://www.linkedin.com/in/matt-severyn-07a38513/',
    picture: require('../images/matt.jpg'),
  },
  {
    name: 'Chris Ma',
    github: 'https://github.com/ma-christopher',
    linkedin: 'https://www.linkedin.com/in/christopher-john-ma/',
    picture: require('../images/chris.png'),
  },
  {
    name: 'Jonathan Ramirez',
    github: 'https://github.com/thecodeswitcher',
    linkedin: 'https://www.linkedin.com/in/jonathan-jim-ramirez/',
    picture: 'https://ca.slack-edge.com/TT68KACBS-UU00KGZ7F-70d7c07f722f-512',
  },
];
