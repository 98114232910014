import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '20rem',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '13rem',
      width: '15rem',
    },
  },
  media: {
    height: '10rem',
    [theme.breakpoints.down('sm')]: {
      height: '7rem',
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

export default function TeamCard({ memberData }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={memberData.picture}
        title={memberData.name}
      />
      <CardContent className={classes.content}>
        <Typography gutterBottom variant='h5' component='h2'>
          {memberData.name}
        </Typography>
      </CardContent>

      <CardActions>
        <Button size='small' color='primary'>
          <a href={memberData.github} target='_blank' rel='noreferrer'>
            <img
              alt='edit'
              src={require('../images/GitHub-Mark-Light-120px-plus.png')}
              width='30px'
              height='30px'
            />
          </a>
        </Button>
        <Button size='small' color='primary'>
          <a href={memberData.linkedin} target='_blank' rel='noreferrer'>
            <img
              alt='edit'
              src={require('../images/noun_linkedin_2045918.png')}
              width='30px'
              height='30px'
            />
          </a>
        </Button>
      </CardActions>
    </Card>
  );
}
