import React from 'react';
import { Link } from 'gatsby';
import '../styles/index.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ModalButton from '../components/WhyButton';
import FeaturesButton from '../components/FeaturesButton';
import TeamButton from '../components/modalButtonTeam';
import { Typography, Button, Grid } from '@material-ui/core';

const IndexPage = (props) => (
  <Layout location={props.location}>
    <SEO title='Home' />
    <div className='main-wrapper'>
      <Grid container>
        <Grid item xs={3} md={2}></Grid>
        <Grid item xs={6} md={8}>
          <div className='logo-header'>
            <Typography variant='h2'>Apollo Epoch</Typography>
            <img
              className='logo'
              src={require('../images/noun_time machine_1581550.png')}
              height='570rem'
              width='660rem'
              alt='logo'
            />
          </div>

          <Grid item xs={3} md={2}></Grid>
        </Grid>
      </Grid>

      <div className='main-button-container'>
        <div className='main-buttons'>
          <ModalButton />
          <FeaturesButton />

          <TeamButton />

          <Button variant='outlined' color='primary' className='button'>
            <Link to='/directions'>Download</Link>
          </Button>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
